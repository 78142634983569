#AboutUs {
    margin-bottom: 0px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(217, 217, 217, .3);
    height: 90vh;
}

#AboutUs .description {
    width: 40%;
    padding: 50px;
    margin-left: 30px;
    text-align: center;
    border-radius: 50px;
    height: 600px;
    color: black;
}

#AboutUs .placeholder {
    width: 50%;
    height: auto;
    background-color: #D9D9D9;
}

#AboutUs .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
}

#AboutUs img {
    border-radius: 20px;
}

#AboutUs .description h1 {
    width: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width:900px) {
    #AboutUs {
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 80px;
    }

    #AboutUs .description {
        width: 75%;
        padding: 20px;
        margin: 10px;
        text-align: center;
    }
    #AboutUs .placeholder {
        border-radius: 20px;
        width: 80%;
    }
    #AboutUs h1 {
        margin-top: 25px;
        margin-bottom: 25px;
    }
}

@media screen and (max-width:425px) {
    #AboutUs {
        margin-bottom: 0px;
    }

    #AboutUs .description h1 {
        font-size: 60px;
    }

    #AboutUs .description {
        font-size: 12px;
        height: 100%;
        padding: 10px;
        text-align: start;
    }

}
