#Landing {
    margin-top: -66px;
    width: 100vw;
    box-sizing: border-box;
}
#Landing .placeholder {
    background-color: #D9D9D9;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: block;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

#Landing img {
    width: 100%;
    height: 100%;
}

/* #Landing .slide-container::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
} */


#Landing .container .sub {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid black;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

#Landing .slogan {
    text-align: center;
    width: 50%;
}


#Landing .right .placeholder {
    width: 200px;
    height : 100%;
}

#Landing .container .button:hover {
    background-color: black;
}

#Landing .container .button {
    margin: 10px;
}

#Landing .container {
    height: 100vh;
    display: flex;
}

.mission-statement {
    /* background-color: #ccd4d6; */
    background-color: rgba(217, 217, 217, 0.4);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f01e2c;
}

.mission-statement .description {
    margin: 0px 100px 0px 100px;
    text-align: start;
    color: black;
}

.mission-statement h1 {
    display: flex;
    width: 85vw;
}

@media screen and (max-width:768px) {

    #Landing .container .sub {
        height: 100%;
    }
    #Landing .container .sub .buttons {
        justify-content: center;
    }
    #Landing img, #Landing .container .sub {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }

    .mission-statement h1 {
        width: 75vw;
    }
}

@media screen and (max-width:425px) {
    #Landing img {
        width: 425px;
        height: 711px;
        object-fit: cover;
    }

    #Landing .container .sub {
        display: flex;
        height: 100%;
        margin-top: 0px;
    }

    #Landing .container .sub .buttons {
        display: flex;
        justify-content: center;
        width: 50vh;
        flex-wrap: wrap;
    }

    .slogan {
        text-align: center;
        width: 100%;
    }
    .mission-statement {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
    }

    .mission-statement .description {
        margin: 0px 30px 0px 30px;
        text-align: center;
    }

    .mission-statement h1 {
        font-size: 60px;
    }
}

@media screen and (max-width:350px) {
    #Landing .button {
        padding: 15px;
        border-radius: 50px;
        color: white;
        font-size: 18px;
    }
}
