#Nav {
    display: flex;
    justify-content: space-evenly;
    position: sticky;
    padding: 10px;
    top: 0;
    /* -webkit-backdrop-filter: blur(2px); */
    /* backdrop-filter: blur(2px); */
    z-index: 100;
    /* background-color: rgba(255, 255, 255, .6); */
    font-size: 18px;
    margin-top: -15px;
}

#Nav .logo {
    text-align: center;
    height: 100%;
    width: 100%;
}

.nav-options {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-options a:hover, #Nav .logo {
    color: black;

}

#Nav .logo {
    width: 130px;
    padding-left: 10px;
}

#Nav .return {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    transition: all .2s ease-in-out;
}

#Nav .return:hover {
    transform: scale(1.1);
}

@media screen and (max-width:600px) {
    .nav-options {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media screen and (max-width:430px) {
    #Nav {
        flex-direction: column;
        margin-top: -60px;
    }
    .nav-options {
        display: flex;
        flex-direction: column;
        margin-top: -20px;
        align-items: flex-end;
    }
}
