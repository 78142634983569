
#Services {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    padding: 30px;
    height: 100%;
}

#Services .header {
    width: 30%;
    padding: 20px;
    height: 100%;
}

#Services h1 {
    margin: 0px;
    font-size: 80px;
}

#Services .header .description {
    font-size: 18px;
    color: black;
}

#Services .services {
    display: flex;
    width: 100%;
    height: 100vh;
    color: red;
    align-items: center;
    text-align: start;
}

#Services .services .card:hover {
    background-color: #ececec;
}

#Services .services .card .description {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    color: grey;
}

#Services .services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#Services .services .card h4 {
    font-size: 18px;
    margin: 0px 0px 10px 0px;
}

#Services .services .card {
    background-color: #efefef;
    border-radius: 10px;
    width: 90%;
    height: 15%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

#Services .services .card .text {
    width: 70%;
}

#Services .services .card:hover {
    background-color: #ececec;
}

@media screen and (max-width:768px) {
    #Services h1 {
        margin: 0px;
        font-size: 50px;
    }

    #Services .services .card img {
        height: 100%;
    }
}

@media screen and (max-width:425px) {
    #Services .header {
        width: 80%;
    }
    #Services {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        height: 150vh;
    }

    #Services .services .card .description {
        font-size: 12px;
    }
    #Services .services .card {
        height: 20%;
    }
    #Services .services .card img {
        height: 60%;
    }
}

@media screen and (max-width:350px) {
    #Services {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        margin: 0px;
    }
}
