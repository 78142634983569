#ReviewForm {
    height: 100vh;
    display: flex;
    justify-content: center;
}

#ReviewForm form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100%;
    width: 50%;
    font-size: 22px;
}

#ReviewForm form .logo {
    width: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    margin-bottom: 20px;
}

#ReviewForm form a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
    margin-bottom: 20px;
}

#ReviewForm .container {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: center;
    align-items: center;
    background-image: url("./images/6AF063DC-46CC-4220-91B9-8C5D8D00D74C_1_201_a.jpeg");
    background-size: 95% 100%;
    background-repeat: no-repeat;
    margin-top: -50px;
}

#ReviewForm .container .text{
    width: 60%;
    padding: 30px;
    margin-top: -300px;
}

#ReviewForm .container .text h1 {
    font-size: 60px;
}

#ReviewForm .container .text div {
    font-size: 20px;
}

#ReviewForm form .row {
    display: flex;
    width: 80%;
}

#ReviewForm form .row label {
    margin-left: 10px;
}
#ReviewForm form .row input, #ReviewForm form .row select, #ReviewForm form .row textarea {
    width: 100%;
    height: 30px;
    margin: 10px;
}

#ReviewForm form .row textarea {
    height: 100px;
    margin-bottom: 20px;
}

#ReviewForm form .row .input {
    width: 100%;
    margin-right: 20px;
}

#ReviewForm form .submit-message {
    margin-top: 20px;
}

#ReviewForm form .row .input .rating {
    display: flex;
}

#ReviewForm form .rating input[type=radio] {
    display: none;
}

#ReviewForm .star {
    cursor: pointer;
}

@media screen and (max-width:800px) {
    #ReviewForm .container {
        display: flex;
        flex-direction: column;
        width: 50%;
        justify-content: center;
        align-items: center;
        background-image: url("./images/6AF063DC-46CC-4220-91B9-8C5D8D00D74C_1_201_a.jpeg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: -50px;
        margin-bottom: -21px;
    }

    #ReviewForm .container .text h1 {
        font-size: 30px;
    }

    #ReviewForm form .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40%;
        margin-bottom: 0px;
    }
}

@media screen and (max-width:768px) {
    #ReviewForm .container {
        margin-bottom: 0px;
    }
}

@media screen and (max-width:425px) {
    #ReviewForm {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: -15px;
        margin-top: 210px;
    }

    #ReviewForm .container {
        display: none;
        /* flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-image: none;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 20px;
        margin-bottom: -21px; */
    }

    /* #ReviewForm .container .text{
        width: 100%;
        height: 300px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: -150px;
        background-image: url("./images/6AF063DC-46CC-4220-91B9-8C5D8D00D74C_1_201_a.jpeg");
        background-size: 100% 150%;
        background-repeat: no-repeat;
        background-position: center;
    } */

    #ReviewForm .container .text div {
        font-size: 18px;
    }

    #ReviewForm form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 22px;
        padding: 0px;
        margin-bottom: 400px;
    }

    #ReviewForm form .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40%;
    }

    #ReviewForm form .row .input {
        width: 100%;
        margin-right: 20px;
    }

    #ReviewForm form .row {
        display: flex;
        flex-direction: column;
        width: 80%;
    }
}

@media screen and (max-width:375px) {
    #ReviewForm .container {
        padding-top: 40px;
        background-size: 100% 150%;
        background-position: center;
    }
    #ReviewForm .container .text h1 {
        font-size: 24px;
        margin-bottom: 5px;
    }
}
@media screen and (max-width:320px) {
    #ReviewForm .container {
        padding-top: 50px;
    }

    #ReviewForm .container .text div {
        font-size: 16px;
    }
}
