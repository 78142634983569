#Footer {
    padding: 20px;
    background-color: #20202E;
    color: white;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100vw;
    justify-content: space-evenly;
    height: 60px;
    box-sizing: border-box;
}

#Footer .left, #Footer .right {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
}

#Footer a {
    color: #FFFFFF;
}

@media screen and (max-width:800px) {
    #Footer .left, #Footer .right {
        width: 50%;
        display: flex;
    }
    #Footer .left {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:430px) {
    #Footer {
        padding: 20px;
        background-color: #20202E;
        color: white;
        display: flex;
        bottom: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        height: 75px;
    }
    .divider {
        color: #20202E;
    }
}
