#Contact {
    display: flex;
    align-items: center;
    height: 90vh;
    padding: 50px;
}

#Contact .container {
    width: 40%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 45px;
    color: #f01e2c;
    padding: 30px;
    border-radius: 50px;

}

#Contact .container h1 {
    margin: 0px;
}

#Contact .arrow {
    width: 100%;
}

#Contact .form {
    width: 70%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    height: 80%;
    justify-content: center;
}

#Contact form input, #Contact form textarea {
    padding: 10px;
    width: 300px;
    margin: 10px;
}

#Contact form textarea {
    width: 90%;
}

#Contact form .checkbox {
    width: 15px;
}

#Contact textarea {
    width: 95%;
}

#Contact .submit-message{
    text-align: center;
    font-size: 24px;
}


button {
    background-color:#f01e2c;
    color: white;
    border-radius: 50px;
    padding: 15px;
    width: 200px;
    border: 2px solid #f01e2c;
}

button:hover {
    background-color: black;
    color: white;
    border-radius: 50px;
    padding: 15px;
    width: 200px;
    cursor: pointer;
    border: 2px solid black;
}

#Contact .row .input {
    flex-direction: column;
    display: flex;
    padding: 10px;
}

.button-container {
    padding: 20px;
    text-align: center;
}

.input label {
    margin-left: 10px;
}

#Contact .row {
    display: flex;
}

#Contact .services {
    padding: 10px;
    box-sizing: border-box;
}

#Contact .services label {
    margin-left: 10px;
}

@media screen and (max-width:1440px) {
    #Contact .container {
        width: 50%;
    }

    #Contact .form {
        width: 50%;
    }
}

@media screen and (max-width:1100px) {
    #Contact {
        display: flex;
        align-items: center;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    #Contact .container {
        width: 80%;
        height: 30%;
        margin: 0px;
        margin-bottom: 20px;
        font-size: 40px;
        text-align: center;
        padding-bottom: 0px;
    }
    #Contact .container h1 {
        margin-bottom: 0px;
    }
}

@media screen and (max-width:425px) {
    #Contact .container {
    width: 60%;
    margin: 50px;
    font-size: 30px;
    font-size: 20px;
    }
    #Contact .form {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0px;
        height: 80%;
        justify-content: center;
    }
    #Contact .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #Contact .services {
        text-align: center;

    }
}

@media screen and (max-width: 380px) {
    #Contact .services {
        padding: 20px;
    }
    #Contact .container {
        width: 80%;
        margin: 40px 20px 20px 20px;
    }
}

@media screen and (max-width: 330px) {
    #Contact form input, #Contact form textarea {
        padding: 10px;
        width: 200px;
        margin: 10px;
    }

    #Contact .services {
        padding: 30px;
    }
}
