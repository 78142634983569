#FullPage {
    font-size: 20px;
    color: #f01e2c;
}

.button {
    padding: 15px;
    border-radius: 50px;
    background-color: #f01e2c;
    color: white;
    transition: all .2s ease-in-out;
}

h3 {
    font-size: 42px;
}
