#Testimonials {
    display: flex;
    margin-bottom: 0px;
    background-attachment: fixed;
    flex-direction: column;
    background-position-y: 60%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    background-size: 200%;
    padding-top: 60px;
    background-color: #f4f4f4;
    height: 100%;
    box-sizing: border-box;
}

#Testimonials .header{
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
}

#Testimonials h1 {
    margin: 0px 0px 30px 0px;
}

#Testimonials .header2{
    display: flex;
    margin-bottom: 100px;
}

#Testimonials .header2 .container{
    display: flex;
    margin-top: 100px;
}

#Testimonials .container {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 50px;
    justify-content: center;
}

#Testimonials .links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    flex-direction: column;
}

#Testimonials h1 {
    font-size: 80px;
}

@media screen and (max-width:1024px) {
    #Testimonials .header2{
        display: flex;
        margin-bottom: 120px;
    }
}

@media screen and (max-width:800px) {
    #Testimonials h1 {
        font-size: 60px;
    }
    #Testimonials {
        margin-bottom: 0px;
    }

}

@media screen and (max-width:425px) {
    #Testimonials {
        display: flex;
        margin-bottom: 0px;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0px 50px 150px 50px;
        box-sizing: border-box;
    }

    #Testimonials .header{
        display: flex;
        margin-bottom: 0px;
    }

    #Testimonials h1 {
        font-size: 45px;
    }
    #Testimonials .container {
        padding: 0px 50px 0px 50px;
        margin-bottom: 50px;
    }
}
@media screen and (max-width:375px) {
    #Testimonials h1 {
        font-size: 30px;
    }
    #Testimonials {
        margin-bottom: 0px;
    }
}
