#Carousel .card {
    width: 20%;
    text-align: center;
    padding: 30px;
    border-radius: 10px;
    margin: 20px;
    height: 85%;
    background-color: #efefef;
    font-size: 16px;
}

#Carousel .card:hover {
    background-color: #ececec;
}

#Carousel .card div {
    margin: 10px;
}

#Carousel .card .review {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#Carousel button:hover {
    border: 0px;
}

#Carousel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
    height: 60vh;
    padding: 30px;
}

#Carousel a:hover{
    cursor: pointer;
}

@media screen and (max-width:768px) {
    #Carousel {
        flex-direction: column;
        height: 100%;
        margin-top: 50px;
    }
    #Carousel .card {
        width: 80%;
        text-align: center;
        padding: 30px;
        border-radius: 10px;
        margin: 20px;
        height: 85%;
        background-color: #efefef;
        font-size: 16px;
    }
}

@media screen and (max-width:425px) {
    #Carousel .card {
        width: 80%;
    }
}

@media screen and (max-width:375px) {
    #Carousel .card {
        width: 70%;
    }
}
