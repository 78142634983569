#Reviews {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    background-size: 100% 100%;
    margin-top: -30px;
    margin-bottom: -100px;
    padding: 0 30px 0 30px;
    box-sizing: border-box;
}

.push {
    height: 100px;
}

#Reviews .reviews {
    display: flex;
    height: 100vh;
    padding: 10px;
    padding-top: 50px;
    align-items: center;
    flex-direction: column;
    overflow: scroll;
}

#Reviews .review-card {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background-color: #efefef;
    padding: 50px;
    width: 80%;
    margin-bottom: 30px;
    text-align: start;
    font-size: 18px;
}

#Reviews .name-date, #Reviews .review-card .rating{
    margin-bottom: 10px;
}

#Reviews .review-card:hover {
    background-color: #ececec;
}

#Reviews .review-card .review {
    font-size: 18px;
}

#Reviews .review-card .date {
    font-size: 12px;
    opacity: 50%;
}

#Reviews .review-card .rating {
    color: #ffc107;
}

#Reviews .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    margin-top: 100px;
}

#Reviews .header h1 {
    margin: 0px;
}

@media screen and (max-width:768px) {
    #Reviews {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    #Reviews .header {
        width: 100%;
        text-align: center;
        font-size: 30px;
        margin-top: 100px;
    }

    #Reviews .reviews {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 100px;
        height: 60%;
        justify-content: center;
    }
    #Reviews .review-card {
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6) ;
        padding: 50px;
        margin: 10px 0px 10px 0px;
        width: 80%;
        height: 20%;
    }
    #Reviews .review-card .name-date{
        width: 100%;
    }
}

@media screen and (max-width:425px) {
    #Reviews {
        min-width: 100vw;
    }
    #Reviews .review-card {
        width: 70%;
        height: 20%;
    }
    #Reviews .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin-top: 100px;
    }
    #Reviews .reviews {
        padding: 50px;
        height: 60%;
    }
}
@media screen and (max-width:425px) {
    #Reviews .reviews {
        padding: 20px;
    }
    #Reviews .review-card {
        width: 60%;
    }
}
